export default [
    {
        label: '번호',
        field: 'orderNumber',
        sort: true
    },
    {
        label: '제목',
        field: 'title',
        sort: true
    },
    {
        label: '대분류',
        field: 'category',
        sort: false
    },
    {
        label: '소분류',
        field: 'subCategory',
        sort: false
    },
    {
        label: '게시자',
        field: 'seller',
        sort: false
    },
];
